<template>
  <section id="finance-report">
    <b-card>
      <b-row>
        <b-col xl="2" md="2">
          <h5>Years</h5>
          <b-form-group>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-prepend>
              <flat-pickr v-model="dateRangesY[1].dateRange" placeholder="Date Range" class="form-control"
                :config="{ mode: 'range' }" @on-change="changeDateRangeY" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col xl="2" md="2">
          <h5>Months</h5>
          <b-form-group>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-prepend>
              <flat-pickr v-model="dateRangesM[1].dateRange" placeholder="Date Range" class="form-control"
                :config="{ mode: 'range' }" @on-change="changeDateRangeM" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="show" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Finance report
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-finance-report" />
          </h4>
          <b-popover target="popover-finance-report" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-button-group>
              <b-overlay :show="showExportFullTableStart" spinner-variant="primary" variant="transparent" blur="3px"
                rounded="sm">
                <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer"
                  @click="exportFullTableToExcel" />
              </b-overlay>
            </b-button-group>
          </b-card-text>
        </b-card-header>
        <div class="table-wrapper">
          <table class="table" ref="fullTable">
            <thead>
              <tr>
                <th class="sticky-col"><b class="text-secondary" style="font-size: 18px;">Bezeichnung</b></th>
                <th class="sticky-col"></th>
                <th v-for="(item, index) in results" :key="index">
                  <b class="text-secondary" style="font-size: 18px;">{{ item.month_year }}<span
                      v-if="includesToday(item)">
                      YTD</span></b><br>
                  <b class="text-secondary" style="font-size: 10px;">{{ item.date_range | formatDateRange }}</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-umsatzerlose">
                    Umsatzerlöse
                  </b-badge>
                  <b-popover target="popover-umsatzerlose" triggers="hover" placement="right">
                    <span>
                      <b>Waren + eingenommen Frachtkosten</b>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <div v-if="item.turnover.turnover_by_tax_type">
                    <b class="text-success">
                      {{ item.turnover.turnover | formatNumber }}
                    </b>
                    <feather-icon :icon="expandedItems[index] ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                      @click="toggleTurnoverByTaxTypeDetail(index)" />
                    <feather-icon icon="DownloadIcon" @click="exportTurnoverPositions(item.date_range)" />
                  </div>
                  <div v-else>
                    <b class="text-success">
                      {{ item.turnover.turnover | formatNumber }}
                    </b>
                    <feather-icon icon="DownloadIcon" @click="exportTurnoverPositions(item.date_range)" />
                  </div>
                  <div v-if="expandedItems[index] && item.turnover.turnover_by_tax_type">
                    <hr>
                    <p v-for="(item2, index2) in item.turnover.turnover_by_tax_type" :key="index2"
                      style="font-size: 11px;">
                      - {{ item2.tax_type }} <b>{{ item2.shipped_from }}</b>: <b class="text-success">
                        {{ item2.turnover | formatNumber }}
                      </b>
                    </p>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm"
                      @click="exportInvoicesByTaxType(item.date_range)">
                      Export
                    </b-button>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow" id="popover-waren">
                    Waren
                  </b-badge>
                  <b-popover target="popover-waren" triggers="hover" placement="right">
                    <span>
                      <b>VKNetto*Anzahl*(1-Rabatt/100)</b> for items where nType IN (0, 1, 4) from RechnungPosition
                      table.
                      StuecklisteRechnungPos, GutschriftPos and Waehrung are taken into account
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <b>{{ item.turnover.turnover_goods | formatNumber }}</b>
                  &nbsp;<feather-icon icon="InfoIcon" @click="openTurnoverGoodsDetails(item.date_range)" />
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow" id="popover-eingenommen-rachtkosten">
                    eingenommen Frachtkosten
                  </b-badge>
                  <b-popover target="popover-eingenommen-rachtkosten" triggers="hover" placement="right">
                    <span>
                      <b>VKNetto*Anzahl*(1-Rabatt/100)</b> for items where nType NOT IN (0, 1, 4) from RechnungPosition
                      table
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <b>{{ item.turnover.freight_charges | formatNumber }}</b>
                  &nbsp;<feather-icon icon="InfoIcon" @click="openFreightChargesInDetails(item.date_range)" />
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-wareneinsatz">
                    Wareneinsatz
                  </b-badge>
                  <b-popover target="popover-wareneinsatz" triggers="hover" placement="right">
                    <span>
                      <b>Waren + Frachtkosten + Nicht gebuchte Lieferantenbestellungen +
                        Lagerbestandsveränderung</b>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ item.expenses.total | formatNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow" id="popover-waren-expenses">
                    Waren
                  </b-badge>
                  <b-popover target="popover-waren-expenses" triggers="hover" placement="right">
                    <span>
                      <b>EKNetto*Menge</b> for items where nPosTyp = 1 OR (nPosTyp = 0 AND cArtNr != '')
                      from EingangsrechnungPos table.
                      LandISO and EingangsrechnungPosZusatzkosten are taken into account, so for LandISO = 'DE' =>
                      <b>EKNetto*fMenge*(1+MwSt/100)</b>
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <b>{{ item.expenses.expenses_goods | formatNumber }}</b>
                  &nbsp;<feather-icon icon="DownloadIcon" @click="exportGoodsCosts(item.date_range)" />
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow" id="popover-frachtkosten">
                    Frachtkosten
                  </b-badge>
                  <b-popover target="popover-frachtkosten" triggers="hover" placement="right">
                    <span>
                      <b>EKNetto*Menge</b> for all trasport items from EingangsrechnungPos table.
                      LandISO and EingangsrechnungPosZusatzkosten are taken into account
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <b>{{ item.expenses.freight_charges | formatNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow" id="popover-nicht-gebuchte-ieferantenbestellungen">
                    Nicht gebuchte Lieferantenbestellungen
                  </b-badge>
                  <b-popover target="popover-nicht-gebuchte-ieferantenbestellungen" triggers="hover" placement="right">
                    <span>
                      Open supplier orders is calculated: <b>EKNetto*Menge*(1+UST/100)</b> for items where Deleted = 0
                      AND Eingangsrechnung IS NULL AND
                      Status IN (500, 650, 700) from LieferantenBestellung table
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <b>{{ item.expenses.open_suporders_amount | formatNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow" id="popover-lagerbestandsveranderung">
                    Lagerbestandsveränderung
                  </b-badge>
                  <b-popover target="popover-lagerbestandsveranderung" triggers="hover" placement="right">
                    <span>
                      Purchasing stock value begin of the period - Purchasing stock value end of the period
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <b>{{ item.stock_value_diff | formatNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge class="badge-glow" id="popover-kostenderverkauftenwaren">
                    Kosten der verkauften Waren
                  </b-badge>
                  <b-popover target="popover-kostenderverkauftenwaren" triggers="hover" placement="right">
                    <span>
                      Cost of goods only for the products that we sold in a month
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b>{{ item.turnover.pprice_goods | formatNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge class="badge-glow" id="popover-korrelationzumumsatz">
                    Korrelation zum Umsatz Waren %
                  </b-badge>
                  <b-popover target="popover-korrelationzumumsatz" triggers="hover" placement="right">
                    <span>
                      Korrelation zum Umsatz = Kosten der verkauften Waren / Umsatz Waren * 100
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b>{{ item.turnover.pprice_p | formatNumber }} %</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-bruttomarge-rohertrag">
                    Bruttomarge (Rohertrag)
                  </b-badge>
                  <b-popover target="popover-bruttomarge-rohertrag" triggers="hover" placement="right">
                    <span>
                      <b>Umsatzerlöse - Wareneinsatz</b>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b v-if="item.gross_margin > 0" class="text-success">
                    {{ item.gross_margin | formatNumber }}
                  </b>
                  <b v-else class="text-danger">
                    {{ item.gross_margin | formatNumber }}
                  </b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-bruttomarge">
                    Bruttomarge %
                  </b-badge>
                  <b-popover target="popover-bruttomarge" triggers="hover" placement="right">
                    <span>
                      <b>Bruttomarge / Umsatzerlöse * 100</b>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b v-if="item.gross_margin_percentage > 0" class="text-success">
                    {{ item.gross_margin_percentage |
                      formatNumber }} %
                  </b>
                  <b v-else class="text-danger">
                    {{ item.gross_margin_percentage |
                      formatNumber }} %
                  </b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-personalkosten">
                    Personalkosten
                  </b-badge>
                  <b-popover target="popover-personalkosten" triggers="hover" placement="right">
                    <span>
                      Sum of all Personalkosten categories
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ item.staff_costs.total | formatNumber }}</b>
                  &nbsp;<feather-icon icon="DownloadIcon" @click="exportStaffCosts(item.date_range)" />
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow" id="popover-lager">
                    Lager
                  </b-badge>
                  <b-popover target="popover-lager" triggers="hover" placement="right">
                    <span>
                      This data is taken from Timemoto API for current month. For past months data is taken from
                      'Übersicht Personalkosten' Google Sheet Table
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <div v-if="item.staff_costs.by_team && item.staff_costs.by_team.length > 0">
                    <b :id="'popover-warehouse' + index">{{item.staff_costs.by_team.find(team => team.team_name ===
                      'Warehouse').total_amount | formatNumber}}</b>
                    <b-popover :target="'popover-warehouse' + index" triggers="hover" placement="left">
                      <div v-for="(i, j) in item.staff_costs.by_team.find(team => team.team_name ===
                        'Warehouse').employees" :key="j">
                        {{ j + 1 }}. {{ i.full_name }} - {{ i.total_amount | formatNumber }}
                      </div>
                    </b-popover>
                  </div>
                  <div v-else>
                    <b>€ 0,00</b>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow" id="popover-verwaltung">
                    Verwaltung
                  </b-badge>
                  <b-popover target="popover-verwaltung" triggers="hover" placement="right">
                    <span>
                      This data is taken from Traqq report for current month. For past months data is taken from
                      'Übersicht Personalkosten' Google Sheet Table
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <div v-if="item.staff_costs.by_team && item.staff_costs.by_team.length > 0">
                    <b :id="'popover-administration' + index">{{item.staff_costs.by_team.find(team => team.team_name
                      ===
                      'Administration').total_amount |
                      formatNumber}}</b>
                    <b-popover :target="'popover-administration' + index" triggers="hover" placement="left">
                      <div v-for="(i, j) in item.staff_costs.by_team.find(team => team.team_name ===
                        'Administration').employees" :key="j">
                        {{ j + 1 }}. {{ i.full_name }} - {{ i.total_amount | formatNumber }}
                      </div>
                    </b-popover>
                  </div>
                  <div v-else>
                    <b>€ 0,00</b>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow" id="popover-einkauf">
                    Einkauf (Dienstl Martinez)
                  </b-badge>
                  <b-popover target="popover-einkauf" triggers="hover" placement="right">
                    <span>
                      This data is taken from Traqq report for current month. For past months data is taken from
                      'Übersicht Personalkosten' Google Sheet Table
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <div v-if="item.staff_costs.by_team && item.staff_costs.by_team.length > 0">
                    <b :id="'popover-purchasing' + index">{{item.staff_costs.by_team.find(team => team.team_name ===
                      'Purchasing').total_amount |
                      formatNumber}}</b>
                    <b-popover :target="'popover-purchasing' + index" triggers="hover" placement="left">
                      <div v-for="(i, j) in item.staff_costs.by_team.find(team => team.team_name ===
                        'Purchasing').employees" :key="j">
                        {{ j + 1 }}. {{ i.full_name }} - {{ i.total_amount | formatNumber }}
                      </div>
                    </b-popover>
                  </div>
                  <div v-else>
                    <b>€ 0,00</b>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow" id="popover-support">
                    Support (Personalkosten)
                  </b-badge>
                  <b-popover target="popover-support" triggers="hover" placement="right">
                    <span>
                      This data is taken from Traqq report for current month. For past months data is taken from
                      'Übersicht Personalkosten' Google Sheet Table
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <div v-if="item.staff_costs.by_team && item.staff_costs.by_team.length > 0">
                    <b :id="'popover-support' + index">{{item.staff_costs.by_team.find(team => team.team_name ===
                      'Support').total_amount | formatNumber
                      }}</b>
                    <b-popover :target="'popover-support' + index" triggers="hover" placement="left">
                      <div v-for="(i, j) in item.staff_costs.by_team.find(team => team.team_name ===
                        'Support').employees" :key="j">
                        {{ j + 1 }}. {{ i.full_name }} - {{ i.total_amount | formatNumber }}
                      </div>
                    </b-popover>
                  </div>
                  <div v-else>
                    <b>€ 0,00</b>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow" id="popover-it">
                    IT (Entwicklungskosten IT)
                  </b-badge>
                  <b-popover target="popover-it" triggers="hover" placement="right">
                    <span>
                      This data is taken from Traqq report for current month. For past months data is taken from
                      'Übersicht Personalkosten' Google Sheet Table
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <div v-if="item.staff_costs.by_team && item.staff_costs.by_team.length > 0">
                    <b :id="'popover-it' + index">{{item.staff_costs.by_team.find(team => team.team_name ===
                      'IT').total_amount | formatNumber}}</b>
                    <b-popover :target="'popover-it' + index" triggers="hover" placement="left">
                      <div v-for="(i, j) in item.staff_costs.by_team.find(team => team.team_name ===
                        'IT').employees" :key="j">
                        {{ j + 1 }}. {{ i.full_name }} - {{ i.total_amount | formatNumber }}
                      </div>
                    </b-popover>
                  </div>
                  <div v-else>
                    <b>€ 0,00</b>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow" id="popover-management">
                    Management (Personalkosten)
                  </b-badge>
                  <b-popover target="popover-management" triggers="hover" placement="right">
                    <span>
                      This data is taken from Traqq report for current month. For past months data is taken from
                      'Übersicht Personalkosten' Google Sheet Table
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <div v-if="item.staff_costs.by_team && item.staff_costs.by_team.length > 0">
                    <b :id="'popover-management' + index">{{item.staff_costs.by_team.find(team => team.team_name ===
                      'Management').total_amount |
                      formatNumber}}</b>
                    <b-popover :target="'popover-management' + index" triggers="hover" placement="left">
                      <div v-for="(i, j) in item.staff_costs.by_team.find(team => team.team_name ===
                        'Management').employees" :key="j">
                        {{ j + 1 }}. {{ i.full_name }} - {{ i.total_amount | formatNumber }}
                      </div>
                    </b-popover>
                  </div>
                  <div v-else>
                    <b>€ 0,00</b>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-marketing-werbe-reisekosten">
                    Marketing (Werbe/Reisekosten)
                  </b-badge>
                  <b-popover target="popover-marketing-werbe-reisekosten" triggers="hover" placement="right">
                    <span>
                      <b>Marketing Budget + Verwaltungskosten</b>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ item.marketing_costs.total | formatNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow" id="popover-marketing-budget">
                    Marketing-Budget
                  </b-badge>
                  <b-popover target="popover-marketing-budget" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'KKS - Kampagnen Zahlen (ALL Monthly)' Google Sheet Table
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <b>{{ item.marketing_costs.ads_expenses | formatNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow" id="popover-marketing">
                    Verwaltungskosten
                  </b-badge>
                  <b-popover target="popover-marketing" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'Marketing' Avg. expenses
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <b>{{ item.marketing_costs.marketing_cost | formatNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-verwaltungskosten">
                    Verwaltungskosten
                  </b-badge>
                  <b-popover target="popover-verwaltungskosten" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'Verwaltungskosten' Avg. expenses
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ item.administrative_cost | formatNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow">
                    Fremdleistungen
                  </b-badge>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <b>€ 0,00</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow">
                    Nach Partnern aufgeschlüsselt
                  </b-badge>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <b>€ 0,00</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-kosten-warenabgabe">
                    Kosten Warenabgabe
                  </b-badge>
                  <b-popover target="popover-kosten-warenabgabe" triggers="hover" placement="right">
                    <span>
                      <b>Onlineshop (nV) + Amazon (Warenabgabe Amazon) + Ebay (Warenabgabe Ebay) + sonstige (nV)</b>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ item.delivery_costs.total | formatNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow">
                    Onlineshop (nV)
                  </b-badge>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <b>€ 0,00</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow" id="popover-amazon">
                    Amazon (Warenabgabe Amazon)
                  </b-badge>
                  <b-popover target="popover-amazon" triggers="hover" placement="right">
                    <span>
                      This data is taken from pf_amazon_settlementpos table and GMI
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <b :id="'popover-amazon' + index">{{ item.delivery_costs.amazon_costs.total | formatNumber
                    }}</b>
                  <b-popover :target="'popover-amazon' + index" triggers="hover" placement="left">
                    <div>1. Fba - {{ item.delivery_costs.amazon_costs.fba | formatNumber }}</div>
                    <div>2. Commission - {{ item.delivery_costs.amazon_costs.commission | formatNumber }}</div>
                    <div>3. Ads - {{ item.delivery_costs.amazon_costs.ads | formatNumber }}</div>
                  </b-popover>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow" id="popover-ebay">
                    Ebay (Warenabgabe Ebay)
                  </b-badge>
                  <b-popover target="popover-ebay" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'Ebay' Avg. expenses
                    </span>
                  </b-popover>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <b>{{ item.delivery_costs.ebay_cost | formatNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col"></td>
                <td class="sticky-col">
                  <b-badge class="badge-glow">
                    sonstige (nV)
                  </b-badge>
                </td>
                <td v-for="(item, index) in results" :key="index">
                  <b>€ 0,00</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-ausgangsfrachten">
                    Ausgangsfrachten
                  </b-badge>
                  <b-popover target="popover-ausgangsfrachten" triggers="hover" placement="right">
                    <span>
                      This data is taken from parsing of PDF invoices (IMAP server and GetMyInvoice service)
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ item.freight_charges_out.total_amount | formatNumber }}</b>
                  &nbsp;<feather-icon icon="InfoIcon" @click="openFreightChargesOutDetails(item.date_range)" />
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-verpackungsmaterial">
                    Verpackungsmaterial
                  </b-badge>
                  <b-popover target="popover-verpackungsmaterial" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'Verpackungsmaterial' Avg. expenses
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ item.packing_material | formatNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-raumkosten">
                    Raumkosten
                  </b-badge>
                  <b-popover target="popover-raumkosten" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'Raumkosten' Avg. expenses
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ item.room_cost | formatNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-versicherungen-beitrage">
                    Versicherungen / Beiträge
                  </b-badge>
                  <b-popover target="popover-versicherungen-beitrage" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'Versicherungen / Beiträge' Avg. expenses
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ item.insurance_contributions | formatNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-sonstige-betriebliche-abgaben">
                    sonstige betriebliche Abgaben (sonstige Kosten)
                  </b-badge>
                  <b-popover target="popover-sonstige-betriebliche-abgaben" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'sonstige betriebliche Abgaben' Avg. expenses
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ item.other_business_taxes | formatNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-betriebliche-steuern">
                    betriebliche Steuern
                  </b-badge>
                  <b-popover target="popover-betriebliche-steuern" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'betriebliche Steuern' Avg. expenses
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ item.business_taxes | formatNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-it-kosten">
                    IT Kosten
                  </b-badge>
                  <b-popover target="popover-it-kosten" triggers="hover" placement="right">
                    <span>
                      This data is taken from 'IT Kosten' Avg. expenses
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ item.it_costs | formatNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-gebuhren-paypal">
                    Gebühren PayPal
                  </b-badge>
                  <b-popover target="popover-gebuhren-paypal" triggers="hover" placement="right">
                    <span>
                      If Zahlungsart from tRechnung table has 'PayPal' then:<br>
                      WHEN sales price bigger then 0 AND sales price lower OR equal 2000 THEN
                      <p><b>PayPal commission = sales price * 0.05229 + 0.35</b></p>
                      WHEN sales price bigger OR equal 2001 AND sales price lower OR equal 5000 THEN
                      <p><b>PayPal commission = sales price * 0.04599 + 0.35</b></p>
                      WHEN sales price bigger OR equal 5001 AND sales price lower OR equal 25000 THEN
                      <p><b>PayPal commission = sales price * 0.04179 + 0.35</b></p>
                      WHEN sales price bigger OR equal 25001 THEN
                      <p><b>PayPal commission = sales price * 0.03759 + 0.35</b></p>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-danger">{{ item.turnover.paypal_commission | formatNumber }}</b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-gesamtkosten">
                    Gesamtkosten
                  </b-badge>
                  <b-popover target="popover-gesamtkosten" triggers="hover" placement="right">
                    <span>
                      <b>Personalkosten + Marketing (Werbe/Reisekosten) + Ausgangsfrachten + Verwaltungskosten + Kosten
                        Warenabgabe +
                        Verpackungsmaterial + Raumkosten + Versicherungen / Beiträge + sonstige betriebliche Abgaben
                        (sonstige Kosten) + betriebliche Steuern + IT Kosten + Gebühren PayPal</b>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b>
                    {{ item.total_costs | formatNumber }}
                  </b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-profit">
                    Profit
                  </b-badge>
                  <b-popover target="popover-profit" triggers="hover" placement="right">
                    <span>
                      <b>Bruttomarge (Rohertrag) - Gesamtkosten</b>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b class="text-success">
                    {{ item.profit | formatNumber }}
                  </b>
                </td>
              </tr>
              <tr>
                <td class="sticky-col">
                  <b-badge variant="primary" class="badge-glow" id="popover-profit-margin">
                    Profit Margin %
                  </b-badge>
                  <b-popover target="popover-profit-margin" triggers="hover" placement="right">
                    <span>
                      <b>Profit / Umsatzerlöse * 100</b>
                    </span>
                  </b-popover>
                </td>
                <td class="sticky-col"></td>
                <td v-for="(item, index) in results" :key="index">
                  <b> {{ item.profit_margin | formatNumber }} % </b>
                  &nbsp;<feather-icon icon="InfoIcon" @click="openProfitMarginDetails(item.date_range)" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br>
        <b-row>
          <b-col md="6">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary"
              @click="openPermanentPaymentDetails">
              Average Expenses
            </b-button>
          </b-col>
          <b-col md="6">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary" @click="openAdvancedCompare">
              Advanced Comparison
            </b-button>
          </b-col>
        </b-row>
        <br>
      </b-card>
    </b-overlay>
    <b-modal ref="turnover-goods-modal" size="xl" title="Turnover By Platform" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true">
      <b-overlay :show="showTurnoverGoodsDetails" spinner-variant="primary" variant="transparent" blur="3px"
        rounded="sm">
        <b-table striped hover responsive class="position-relative" :current-page="turnoverGoodsTable.currentPage"
          :items="turnoverGoodsTable.items" :fields="turnoverGoodsTable.fields"
          :sort-by.sync="turnoverGoodsTable.sortBy" :sort-desc.sync="turnoverGoodsTable.sortDesc"
          :sort-direction="turnoverGoodsTable.sortDirection" :filter="turnoverGoodsTable.filter"
          :filter-included-fields="turnoverGoodsTable.filterOn" />

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="turnoverGoodsTable.currentPage" :total-rows="turnoverGoodsTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-overlay>
    </b-modal>
    <b-modal ref="freight-charges-in-modal" size="xl" title="Freight Charges By Shipping Method" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true">
      <b-overlay :show="showFreightChargesInDetails" spinner-variant="primary" variant="transparent" blur="3px"
        rounded="sm">
        <b-table striped hover responsive class="position-relative"
          :current-page="freightChargesMethodTable.currentPage" :items="freightChargesMethodTable.items"
          :fields="freightChargesMethodTable.fields" :sort-by.sync="freightChargesMethodTable.sortBy"
          :sort-desc.sync="freightChargesMethodTable.sortDesc" :sort-direction="freightChargesMethodTable.sortDirection"
          :filter="freightChargesMethodTable.filter" :filter-included-fields="freightChargesMethodTable.filterOn" />

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="freightChargesMethodTable.currentPage"
              :total-rows="freightChargesMethodTable.totalRows" first-number last-number prev-class="prev-item"
              next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-overlay>
    </b-modal>
    <b-modal ref="freight-charges-out-modal" size="xl" title="Freight Charges By Providers" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true">
      <b-overlay spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <b-table striped hover responsive class="position-relative" :current-page="freightChargesOutTable.currentPage"
          :items="freightChargesOutTable.items" :fields="freightChargesOutTable.fields"
          :sort-by.sync="freightChargesOutTable.sortBy" :sort-desc.sync="freightChargesOutTable.sortDesc"
          :sort-direction="freightChargesOutTable.sortDirection" :filter="freightChargesOutTable.filter"
          :filter-included-fields="freightChargesOutTable.filterOn" />

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="freightChargesOutTable.currentPage" :total-rows="freightChargesOutTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-overlay>
    </b-modal>
    <b-modal ref="charts-by-month-modal" size="xl" title="Charts By Month" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true" @shown="onChartsByMonthModalShown">
      <b-row>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Turnover
              </h4>
            </b-card-header>
            <ECharts ref="turnoverByMonthChart" :options="turnoverByMonthChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Gross / Profit Margin
              </h4>
            </b-card-header>
            <ECharts ref="profitGrossMarginByMonthChart" :options="profitGrossMarginByMonthChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal ref="permanent-payments-modal" size="xl" title="Avg. Expenses" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true">
      <b-table striped hover responsive class="position-relative" :current-page="permanentPaymentsTable.currentPage"
        :items="permanentPaymentsTable.items" :fields="permanentPaymentsTable.fields"
        :sort-by.sync="permanentPaymentsTable.sortBy" :sort-desc.sync="permanentPaymentsTable.sortDesc"
        :sort-direction="permanentPaymentsTable.sortDirection" :filter="permanentPaymentsTable.filter"
        :filter-included-fields="permanentPaymentsTable.filterOn" @row-clicked="permanentPaymentsRowClicked">
        <template #cell(is_avg_payment)="data">
          <div v-if="data.item.is_avg_payment">
            <feather-icon icon="CheckCircleIcon" />
          </div>
          <div v-else>
            <feather-icon icon="XCircleIcon" />
          </div>
        </template>
        <template #cell(delete)="data">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat" class="btn-icon"
            @click="openDeleteConfirm(data.item.id)">
            <feather-icon icon="Trash2Icon" />
          </b-button>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- pagination -->
        <div>
          <b-pagination v-model="permanentPaymentsTable.currentPage" :total-rows="permanentPaymentsTable.totalRows"
            first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
            @change="handlePermanentPaymentsPageChange">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-row>
        <b-col xl="8" md="8">
        </b-col>
        <b-col xl="4" md="4">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
            @click="addPermanentPaymentClicked">
            Add Avg. Expenses
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal ref="advanced-compare-modal" size="xl" title="Advanced compare" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true">
      <b-tabs>
        <b-tab title="Compare turnover data">
          <b-overlay :show="showCompareTurnoverResults" spinner-variant="primary" variant="transparent" blur="3px"
            rounded="sm">
            <b-row>
              <b-col xl="6">
                <b-card title="Step 1. Upload file">
                  <b-form-file v-model="compareTurnoverUploadedFile" placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..." />
                </b-card>
              </b-col>
              <b-col xl="6">
                <b-card title="Step 2. Select date range">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-prepend>
                    <flat-pickr v-model="compareTurnoverDateRange" placeholder="Date Range" class="form-control"
                      :config="{ mode: 'range' }" />
                  </b-input-group>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col xl="6">
                <b-card title="Step 3. Compare with JTL">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
                    @click="runCompareTurnoverData">
                    Compare
                  </b-button>
                </b-card>
              </b-col>
              <b-col xl="6">
                <b-card title="Results">
                  <a :href="linkToTurnoverCompareTable" target="_blank">{{ linkToTurnoverCompareTable }}</a>
                </b-card>
              </b-col>
            </b-row>
          </b-overlay>
        </b-tab>
        <b-tab title="Compare expenses data">
          <b-overlay :show="showCompareExpensesResults" spinner-variant="primary" variant="transparent" blur="3px"
            rounded="sm">
            <b-row>
              <b-col xl="4">
                <b-card title="Step 1. Upload file">
                  <b-form-file v-model="compareExpensesUploadedFile" placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..." />
                </b-card>
              </b-col>
              <b-col xl="4">
                <b-card title="Step 2. Select date range">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-prepend>
                    <flat-pickr v-model="compareExpensesDateRange" placeholder="Date Range" class="form-control"
                      :config="{ mode: 'range' }" />
                  </b-input-group>
                </b-card>
              </b-col>
              <b-col xl="4">
                <b-card title="Step 3. Select expenses type">
                  <v-select v-model="selectedExpensesType" label="title" placeholder="Expenses Type"
                    :options="allExpensesTypes" />
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col xl="12">
                <b-card title="Step 4. Compare with JTL">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
                    @click="runCompareExpensesData">
                    Compare
                  </b-button>
                </b-card>
              </b-col>
            </b-row>
            <b-card title="Link to Google table">
              <a :href="linkToExpensesCompareTable" target="_blank">{{ linkToExpensesCompareTable }}</a>
            </b-card>
          </b-overlay>
        </b-tab>
      </b-tabs>
      <b-card title="Previously created files">
        <b-badge v-for="(item, index) in googleDriveFiles" :key="index" class="google-drive-files" variant="primary">
          <a :href="item.link" target="_blank">{{ item.name }}</a>
        </b-badge>
      </b-card>
    </b-modal>
    <b-modal ref="modal-permanent-payment-submit" cancel-variant="outline-secondary" ok-title="Submit"
      cancel-title="Close" centered :title="permanentPaymentID ? 'Update' : 'Add'" :no-close-on-backdrop="true"
      @ok="handlePermanentPaymentSubmit" @hidden="resetPermanentPaymentModal">
      <b-form @submit.prevent>
        <b-form-group label="Name" label-for="permanentPaymentName">
          <b-form-input id="permanentPaymentName" v-model="permanentPaymentName" />
        </b-form-group>
        <b-form-group label="Recurring Type" label-for="permanentPaymentType">
          <v-select id="permanentPaymentType" v-model="permanentPaymentType" label="title"
            :options="permanentPaymentTypes" />
        </b-form-group>
        <b-form-group label="Value" label-for="permanentPaymentValue">
          <b-form-input id="permanentPaymentValue" v-model="permanentPaymentValue" type="number" />
        </b-form-group>
        <b-form-group label="Start Date Period" label-for="permanentPaymentStartDate">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr id="permanentPaymentStartDate" v-model="permanentPaymentStartDate" class="form-control" />
          </b-input-group>
        </b-form-group>
        <b-form-group label="End Date Period" label-for="permanentPaymentEndDate">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr id="permanentPaymentEndDate" v-model="permanentPaymentEndDate" class="form-control" />
          </b-input-group>
        </b-form-group>
        <b-form-group label="Payment Term" label-for="permanentPaymentDate">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr id="permanentPaymentDate" v-model="permanentPaymentDate" class="form-control" />
          </b-input-group>
        </b-form-group>
        <b-form-group label="Avg. Payment" label-for="is-avg-payment">
          <b-form-checkbox id="is-avg-payment" v-model="isAvgPayment">
          </b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
  BCardHeader,
  BCardText,
  BButtonGroup,
  BOverlay,
  BTable,
  BPagination,
  BButton,
  BBadge,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BPopover,
  BTab,
  BTabs,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import Ripple from 'vue-ripple-directive';
import axios from 'axios';
import * as XLSX from 'xlsx';
import ECharts from 'vue-echarts';

import 'echarts/lib/chart/bar';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const EUCountryCodes = [
  'BE', 'BG', 'CZ', 'DK', 'EE', 'IE', 'EL', 'ES', 'FR', 'HR',
  'IT', 'CY', 'LV', 'LT', 'LU', 'HU', 'MT', 'NL', 'AT', 'PL',
  'PT', 'RO', 'SI', 'SK', 'FI', 'SE',
];

const currentDate = new Date();
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

function getPreviousMonthRange(currentDate) {
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  // Determine the previous month and its year
  const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1; // Adjust month index for array (0-indexed)
  const previousYear = currentMonth === 0 ? currentYear - 1 : currentYear;

  // Calculate the last day of the previous month
  const lastDay = new Date(currentYear, currentMonth, 0).getDate(); // Day 0 of the current month gives the last day of the previous month

  // Format the previous month's date range
  const dateRange = `${previousYear}-${formatMonth(new Date(previousYear, previousMonth, 1))}-01 to ${previousYear}-${formatMonth(new Date(previousYear, previousMonth, 1))}-${formatDate(new Date(previousYear, previousMonth, lastDay))}`;
  return dateRange;
};
const previousMonthDateRange = getPreviousMonthRange(currentDate);

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BCardHeader,
    BCardText,
    BButtonGroup,
    BOverlay,
    BTable,
    BPagination,
    BButton,
    BBadge,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BPopover,
    BTab,
    BTabs,
    vSelect,
    flatPickr,
    ToastificationContent,
    ECharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: true,
      showFreightChargesInDetails: true,
      showExportFullTableStart: false,
      showCompareTurnoverResults: false,
      showCompareExpensesResults: false,
      showTurnoverGoodsDetails: true,
      expandedItems: {},
      dateRangesM: [
        {
          oldDateRange: previousMonthDateRange,
          dateRange: previousMonthDateRange
        },
        {
          oldDateRange: `${currentDate.getFullYear()}-${formatMonth(currentDate)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
          dateRange: `${currentDate.getFullYear()}-${formatMonth(currentDate)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`
        },
      ],
      dateRangesY: [
        {
          oldDateRange: `${currentDate.getFullYear() - 1}-01-01 to ${currentDate.getFullYear() - 1}-12-31`,
          dateRange: `${currentDate.getFullYear() - 1}-01-01 to ${currentDate.getFullYear() - 1}-12-31`
        },
        {
          oldDateRange: `${currentDate.getFullYear() - 1}-01-01 to ${currentDate.getFullYear() - 1}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
          dateRange: `${currentDate.getFullYear() - 1}-01-01 to ${currentDate.getFullYear() - 1}-${formatMonth(currentDate)}-${formatDate(currentDate)}`
        },
        {
          oldDateRange: `${currentDate.getFullYear()}-01-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
          dateRange: `${currentDate.getFullYear()}-01-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`
        },
      ],
      compareTurnoverUploadedFile: [],
      compareExpensesUploadedFile: [],
      results: [],
      permanentPaymentID: '',
      permanentPaymentTypes: ['once', 'monthly', 'quarterly', 'yearly'],
      permanentPaymentName: '',
      permanentPaymentValue: 0.0,
      permanentPaymentStartDate: '',
      permanentPaymentEndDate: '',
      permanentPaymentDate: '',
      permanentPaymentType: '',
      isAvgPayment: true,
      queryParamsPermanentPayments: {},
      compareTurnoverDateRange: '',
      compareExpensesDateRange: '',
      selectedExpensesType: '',
      allExpensesTypes: ['Waren Frachtkosten', 'Ausgangsfrachten'],
      linkToTurnoverCompareTable: '',
      linkToExpensesCompareTable: '',
      googleDriveFiles: [],
      turnoverGoodsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'platform', label: 'platform', sortable: true },
          {
            key: 'turnover',
            label: 'amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      freightChargesMethodTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'freight_charges',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'method', label: 'method', sortable: true },
          {
            key: 'freight_charges',
            label: 'freight charges',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      freightChargesOutTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'total_amount',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'provider', label: 'provider' },
          {
            key: 'amount_curr_year',
            label: 'amount selected year',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'amount_prev_year',
            label: 'amount prev. year same period',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'absolute_change',
            label: 'change',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'percentage_change',
            label: 'percentage change',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatNumber(val)} %`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'amount_prev_full_year',
            label: 'amount prev. full year',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      permanentPaymentsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'name',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'value',
            label: 'total amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'recurring_type', label: 'recurring type', sortable: true },
          {
            key: 'start_date',
            label: 'start date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'end_date',
            label: 'end date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'payment_date',
            label: 'payment term',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          { key: 'is_avg_payment', label: 'avg. payment', sortable: true },
          { key: 'delete', label: '' },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      turnoverByMonthChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            interval: 0,
            rotate: 45,
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        grid: {
          bottom: 100 // Adds extra space at the bottom of the chart
        },
        series: [],
      },
      profitGrossMarginByMonthChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            interval: 0,
            rotate: 45,
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        grid: {
          bottom: 100 // Adds extra space at the bottom of the chart
        },
        series: [],
      },
    }
  },
  async created() {
    this.show = true;

    // Grouped by years
    for (let i = 0; i < this.dateRangesY.length; i++) {
      const dateRange = this.dateRangesY[i].dateRange.split(' to ');
      let queryParams = {
        from_date: dateRange[0],
        to_date: dateRange[1],
        group_by: 'year',
      };
      await this.getData(queryParams);
    }

    // Grouped by months
    for (let i = 0; i < this.dateRangesM.length; i++) {
      const dateRange = this.dateRangesM[i].dateRange.split(' to ');
      let queryParams = {
        from_date: dateRange[0],
        to_date: dateRange[1],
        group_by: 'month',
      };
      await this.getData(queryParams);
    }

    // Get Avg Expenses
    await this.getPermanentPayments();

    this.show = false;
  },
  filters: {
    formatDateRange(value) {
      const parts = value.split(' to ');
      if (parts.length !== 2) {
        return value;  // Return the original string if it doesn't match expected format
      }
      const [startDate, endDate] = parts;
      const formattedStartDate = startDate.split('-').reverse().join('.');
      const formattedEndDate = endDate.split('-').reverse().join('.');
      return `${formattedStartDate} to ${formattedEndDate}`;
    }
  },
  methods: {
    calculateTrendLine(data) {
      const n = data.length;
      const x = Array.from({ length: n }, (_, i) => i + 1);
      const sumX = x.reduce((a, b) => a + b, 0);
      const sumY = data.reduce((a, b) => a + b, 0);
      const sumXY = x.reduce((sum, xi, i) => sum + xi * data[i], 0);
      const sumX2 = x.reduce((sum, xi) => sum + xi * xi, 0);

      const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
      const intercept = (sumY - slope * sumX) / n;

      return x.map(xi => slope * xi + intercept);
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'BellIcon',
          variant,
        },
      })
    },
    includesToday(item) {
      const today = new Date();
      const currentYear = today.getFullYear();
      const monthAsString = String(item.month_year); // Ensure item.month is treated as a string

      // Check if the month is strictly a year (4 digits) and is less than the current year
      if (monthAsString.match(/^\d{4}$/) && parseInt(monthAsString, 10) < currentYear) {
        const [startDateStr, endDateStr] = item.date_range.split(' to ');

        const endDate = new Date(endDateStr.trim());
        const endMonth = endDate.getMonth(); // Month (0-11)
        const endDay = endDate.getDate(); // Day (1-31)

        // Compare endDate's month and day with today's month and day
        return today.getDate() === endDay && today.getMonth() === endMonth;
      }
      return false; // If the conditions are not met, return false
    },
    formatDateToLocal(date) {
      const tzOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
      const localISOTime = (new Date(date - tzOffset)).toISOString().slice(0, 10);
      return localISOTime;
    },
    getMonthlyDateRanges(startDate, endDate) {
      const ranges = [];
      let current = new Date(startDate);
      const end = new Date(endDate);

      while (current <= end) {
        // Start of the month for 'current' or the actual start date if it's the first loop iteration
        const adjustedStart = new Date(current.getFullYear(), current.getMonth(), current.getDate() === 1 ? 1 : current.getDate());

        // Calculate the last day of the current month
        const endOfMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0);

        // End of the month or the actual end date if it falls within this month
        const adjustedEnd = endOfMonth > end ? end : endOfMonth;

        // Add the current month's range to the array
        ranges.push({
          from_date: this.formatDateToLocal(adjustedStart),
          to_date: this.formatDateToLocal(adjustedEnd)
        });

        // Move 'current' to the first day of the next month
        current = new Date(adjustedStart.getFullYear(), adjustedStart.getMonth() + 1, 1);
      }

      return ranges;
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getData(queryParams) {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/finance-report/`, queryParams);
        const results = response.data.results;
        this.results.push(results[0]);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getChartsByMonth(queryParams) {
      this.show = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/finance-report/`, queryParams);
        const results = response.data.results;

        this.turnoverByMonthChart.xAxis.data = results.map(item => item.month_year);
        this.turnoverByMonthChart.series = [{
          name: 'Turnover',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.turnover.turnover),
          markPoint: {
            data: [
              { type: 'max', name: 'Max' },
              { type: 'min', name: 'Min' }
            ]
          },
          markLine: {
            symbol: 'none',
            data: [{ type: 'average', name: 'Avg' }]
          }
        }];

        this.profitGrossMarginByMonthChart.xAxis.data = results.map(item => item.month_year);
        this.profitGrossMarginByMonthChart.series[0] = {
          name: 'Gross Margin',
          type: 'line',
          label: {
            show: true,
            position: 'top',
            distance: 5,
            align: 'left',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          },
          data: results.map(item => item.gross_margin_percentage),
          markPoint: {
            data: [
              { type: 'max', name: 'Max' },
              { type: 'min', name: 'Min' }
            ]
          },
          markLine: {
            symbol: 'none',
            data: [{ type: 'average', name: 'Avg' }]
          }
        };
        this.profitGrossMarginByMonthChart.series[1] = {
          name: 'Profit Margin',
          type: 'line',
          label: {
            show: true,
            position: 'top',
            distance: 5,
            align: 'left',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          },
          data: results.map(item => item.profit_margin),
          markPoint: {
            data: [
              { type: 'max', name: 'Max' },
              { type: 'min', name: 'Min' }
            ]
          },
          markLine: {
            symbol: 'none',
            data: [{ type: 'average', name: 'Avg' }]
          }
        };
        this.profitGrossMarginByMonthChart.series[2] = {
          name: 'Gross Margin Trend',
          type: 'line',
          data: this.calculateTrendLine(results.map(item => item.gross_margin_percentage)),
          lineStyle: {
            type: 'dashed',
            color: 'blue',
          },
        };
        this.profitGrossMarginByMonthChart.series[3] = {
          name: 'Profit Margin Trend',
          type: 'line',
          data: this.calculateTrendLine(results.map(item => item.profit_margin)),
          lineStyle: {
            type: 'dashed',
            color: 'red'
          },
        };

      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.show = false;
      }
    },
    async getPermanentPayments() {
      this.queryParamsPermanentPayments.is_avg_payment = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/permanent-payments/`, this.queryParamsPermanentPayments);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.permanentPaymentsTable.items = results;
          this.permanentPaymentsTable.totalRows = results.length;
        }
        else {
          this.permanentPaymentsTable.items = [];
          this.permanentPaymentsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getTurnoverGoods(dr) {
      this.showTurnoverGoodsDetails = true;
      try {
        const dateRange = dr.split(' to ');
        let queryParams = {
          from_date: dateRange[0],
          to_date: dateRange[1]
        };
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-by-platform/`, queryParams);
        this.turnoverGoodsTable.items = response.data.results;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showTurnoverGoodsDetails = false;
      }
    },
    async getFreightChargesIn(dr) {
      this.showFreightChargesInDetails = true;

      const dateRange = dr.split(' to ');
      let queryParams = {
        from_date: dateRange[0],
        to_date: dateRange[1]
      };

      const requestFreightChargesIn = await axios.get(`${process.env.VUE_APP_ROOT_API}/freight-charges-method/`, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params: queryParams,
      })
      const requestShippingMethods = await axios.get(`${process.env.VUE_APP_ROOT_API}/all-shipping-methods/`, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
      })

      await axios
        .all([
          requestFreightChargesIn,
          requestShippingMethods,
        ])
        .then(response => {
          const shippingMethods = response[1].data;
          const freightChargesMethodIn = response[0].data.results;

          // Map by name
          freightChargesMethodIn.forEach(i => {
            shippingMethods.forEach(j => {
              if (i.method === j.name) {
                i.method = j.maps_to_name.name;
              }
            })
          })

          // Group by method name
          const resultFreightChargesMethodIn = [];
          freightChargesMethodIn.reduce((res, value) => {
            if (!res[value.method]) {
              res[value.method] = { method: value.method, freight_charges: 0 };
              resultFreightChargesMethodIn.push(res[value.method]);
            }
            res[value.method].freight_charges += value.freight_charges;
            return res;
          }, {});

          this.freightChargesMethodTable.items = resultFreightChargesMethodIn;
          this.showFreightChargesInDetails = false;
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$router.push({ name: 'login' });
          }
        })
    },
    async getFreightChargesOut(dr) {
      this.show = true;
      const dateRange = dr.split(' to ');
      let fromDate = new Date(dateRange[0]);
      let toDate = new Date(dateRange[1]);

      const queryParamsList = [
        [`${fromDate.getFullYear()}-${formatMonth(fromDate)}-${formatDate(fromDate)}`, `${toDate.getFullYear()}-${formatMonth(toDate)}-${formatDate(toDate)}`],
        [`${fromDate.getFullYear() - 1}-${formatMonth(fromDate)}-${formatDate(fromDate)}`, `${toDate.getFullYear() - 1}-${formatMonth(toDate)}-${formatDate(toDate)}`],
        [`${fromDate.getFullYear() - 1}-01-${formatDate(fromDate)}`, `${toDate.getFullYear() - 1}-12-31`]
      ];
      const queryParams = {};
      let currResults = [];
      for (let i = 0; i < queryParamsList.length; i++) {
        queryParams.from_date = queryParamsList[i][0];
        queryParams.to_date = queryParamsList[i][1];
        queryParams.method = 'All';
        try {
          const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/freight-charges-out-jtl/`, queryParams);
          currResults.push(response.data.results);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'login' });
          } else {
            // Handle other types of errors
            console.error('An error occurred:', error);
          }
        }
      }

      let combinedArray = [];
      currResults.forEach((subArray, index) => {
        // Aggregate amounts by provider within each sub-array
        const aggregatedByProvider = {};

        subArray.forEach(item => {
          const providers = [
            { field: 'dp_amount', name: 'Deutsche Post' },
            { field: 'schenker_amount', name: 'Schenker' },
            { field: 'ppl_amount', name: 'PPL' }
          ];

          providers.forEach(provider => {
            const amount = parseFloat(item[provider.field] || 0);

            if (amount > 0) {
              if (!aggregatedByProvider[provider.name]) {
                aggregatedByProvider[provider.name] = 0;
              }
              aggregatedByProvider[provider.name] += amount; // Sum amounts across months
            }
          });
        });

        // Process the aggregated data
        Object.keys(aggregatedByProvider).forEach(providerName => {
          const amount = aggregatedByProvider[providerName];

          let existingItem = combinedArray.find(ci => ci.provider === providerName);

          if (!existingItem) {
            existingItem = {
              provider: providerName,
              amount_curr_year: 0,
              amount_prev_year: 0,
              absolute_change: 0,
              percentage_change: 0,
              amount_prev_full_year: 0,
            };
            combinedArray.push(existingItem);
          }

          if (index === 0) {
            existingItem.amount_curr_year = amount;
          } else if (index === 1) {
            existingItem.amount_prev_year = amount;
          } else {
            existingItem.amount_prev_full_year = amount;
          }

          // Calculate the absolute and percentage change
          if (existingItem.amount_curr_year && existingItem.amount_prev_year) {
            let absoluteChange = existingItem.amount_curr_year - existingItem.amount_prev_year;
            existingItem.absolute_change = absoluteChange;
            existingItem.percentage_change = (absoluteChange / existingItem.amount_prev_year) * 100;
          }
        });
      });

      const amountCurrYearSum = combinedArray.map(item => item.amount_curr_year).reduce((a, b) => (a + b));
      const amountPrevYearSum = combinedArray.map(item => item.amount_prev_year).reduce((a, b) => (a + b));
      const absoluteChangerSum = amountCurrYearSum - amountPrevYearSum;
      const percentageChangerSum = (absoluteChangerSum / amountPrevYearSum) * 100;
      const amountPrevFullYearSum = combinedArray.map(item => item.amount_prev_full_year).reduce((a, b) => (a + b));

      combinedArray.push({
        provider: "Total",
        amount_curr_year: amountCurrYearSum,
        amount_prev_year: amountPrevYearSum,
        absolute_change: absoluteChangerSum,
        percentage_change: percentageChangerSum,
        amount_prev_full_year: amountPrevFullYearSum,
      });

      this.freightChargesOutTable.items = combinedArray;

      this.show = false;
    },
    async getAllComparedFiles() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-compared-files/`, {});
        this.googleDriveFiles = response.data.files;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async changeDateRangeY() {
      this.show = true;
      const dateRange = this.dateRangesY[1].dateRange.split(' to ');
      const oldDateRange = this.dateRangesY[1].oldDateRange.split(' to ');
      if (dateRange.length === 2 && dateRange[0] && dateRange[1]) {
        // Check if the new date range is different from the old one
        if (dateRange[0] !== oldDateRange[0] || dateRange[1] !== oldDateRange[1]) {
          let queryParams = {
            from_date: dateRange[0],
            to_date: dateRange[1],
            group_by: 'year',
          };
          await this.getData(queryParams);
          // Update the oldDateRange to the new dateRange after fetching data
          this.dateRangesY[1].oldDateRange = this.dateRangesY[1].dateRange;
        }
      }
      this.show = false;
    },
    async changeDateRangeM() {
      this.show = true;
      const dateRange = this.dateRangesM[1].dateRange.split(' to ');
      const oldDateRange = this.dateRangesM[1].oldDateRange.split(' to ');
      if (dateRange.length === 2 && dateRange[0] && dateRange[1]) {
        // Check if the new date range is different from the old one
        if (dateRange[0] !== oldDateRange[0] || dateRange[1] !== oldDateRange[1]) {
          const monthlyRanges = this.getMonthlyDateRanges(dateRange[0], dateRange[1]);
          for (const range of monthlyRanges) {
            await this.getData(range);
          }
          // Update the oldDateRange to the new dateRange after fetching data
          this.dateRangesM[1].oldDateRange = this.dateRangesM[1].dateRange;
        }
      }
      this.show = false;
    },
    async openTurnoverGoodsDetails(dateRange) {
      await this.getTurnoverGoods(dateRange);
      this.$refs['turnover-goods-modal'].show();
    },
    async openFreightChargesInDetails(dateRange) {
      await this.getFreightChargesIn(dateRange);
      this.$refs['freight-charges-in-modal'].show();
    },
    async openFreightChargesOutDetails(dateRange) {
      await this.getFreightChargesOut(dateRange);
      this.$refs['freight-charges-out-modal'].show();
    },
    async openProfitMarginDetails(dateRange) {
      const dateRangeArr = dateRange.split(' to ');
      let queryParams = {
        from_date: dateRangeArr[0],
        to_date: dateRangeArr[1],
        group_by: 'month',
      };
      await this.getChartsByMonth(queryParams);
      this.$refs['charts-by-month-modal'].show();
    },
    onChartsByMonthModalShown() {
      this.$nextTick(() => {
        this.$refs.turnoverByMonthChart.resize();
        this.$refs.profitGrossMarginByMonthChart.resize();
      });
    },
    openPermanentPaymentDetails() {
      this.$refs['permanent-payments-modal'].show();
    },
    async handlePermanentPaymentsPageChange(value) {
      this.queryParamsPermanentPayments.page = value;
      await this.getPermanentPayments();
    },
    async openAdvancedCompare() {
      await this.getAllComparedFiles();
      this.$refs['advanced-compare-modal'].show();
    },
    addPermanentPaymentClicked() {
      this.$refs['modal-permanent-payment-submit'].show();
    },
    permanentPaymentsRowClicked(row) {
      this.permanentPaymentID = row.id;
      this.permanentPaymentName = row.name;
      this.permanentPaymentValue = row.value;
      this.permanentPaymentStartDate = row.start_date;
      this.permanentPaymentEndDate = row.end_date;
      this.permanentPaymentDate = row.payment_date;
      this.permanentPaymentType = row.recurring_type;
      this.isAvgPayment = row.is_avg_payment;
      this.$refs['modal-permanent-payment-submit'].show();
    },
    resetPermanentPaymentModal() {
      this.permanentPaymentID = '';
      this.permanentPaymentName = '';
      this.permanentPaymentValue = 0.0;
      this.permanentPaymentStartDate = '';
      this.permanentPaymentEndDate = '';
      this.permanentPaymentDate = '';
      this.permanentPaymentType = '';
      this.isAvgPayment = true;
    },
    toggleTurnoverByTaxTypeDetail(index) {
      // Toggle the expanded state for the given index
      this.$set(this.expandedItems, index, !this.expandedItems[index]);
    },
    async handlePermanentPaymentSubmit() {
      const params = {
        name: this.permanentPaymentName,
        value: this.permanentPaymentValue,
        start_date: this.permanentPaymentStartDate,
        end_date: this.permanentPaymentEndDate,
        payment_date: this.permanentPaymentDate,
        recurring_type: this.permanentPaymentType,
        is_avg_payment: this.isAvgPayment,
      }

      if (this.permanentPaymentID
        && this.planNumbersName !== ''
        && this.permanentPaymentValue !== 0.0
        && this.permanentPaymentStartDate
        && this.permanentPaymentEndDate
        && this.permanentPaymentDate
        && this.permanentPaymentType !== '') {
        await axios
          .put(`${process.env.VUE_APP_ROOT_API}/permanent-payments/${this.permanentPaymentID}/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Permanent payment has been updated');
            await this.getPermanentPayments();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      } else if (this.planNumbersName !== ''
        && this.permanentPaymentValue !== 0.0
        && this.permanentPaymentStartDate
        && this.permanentPaymentEndDate
        && this.permanentPaymentDate
        && this.permanentPaymentType !== '') {
        await axios
          .post(`${process.env.VUE_APP_ROOT_API}/permanent-payments/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Permanent payment created');
            await this.getPermanentPayments();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      } else { this.showToast('danger', 'Error!') }
    },
    openDeleteConfirm(id) {
      this.permanentPaymentID = id;
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await axios
            .delete(`${process.env.VUE_APP_ROOT_API}/permanent-payments/${this.permanentPaymentID}/`, {
              headers: {
                Authorization: `JWT ${this.$store.state.jwt}`,
                'Content-Type': 'application/json',
              },
            })
            .then(async response => {
              console.log(response);
              this.showToast('info', 'Permanent payment has been deleted');
              await this.getPermanentPayments();
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$router.push({ name: 'login' });
              }
            })
        }
        this.permanentPaymentID = '';
      })
    },
    async exportGoodsCosts(dr) {
      try {
        const dateRange = dr.split(' to ');
        let queryParams = {
          from_date: dateRange[0],
          to_date: dateRange[1]
        };
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/goods-costs/`, queryParams);

        const exportedData = response.data.results;
        const euExportedData = [];
        const deExportedData = [];
        const otherExportedData = [];

        // Classify the data into different arrays based on the country code
        exportedData.forEach(item => {
          if (EUCountryCodes.includes(item.country_code)) {
            euExportedData.push(item);
          } else if (item.country_code === 'DE') {
            deExportedData.push(item);
          } else {
            otherExportedData.push(item);
          }
        });

        // Create a new workbook
        let workbook = XLSX.utils.book_new();

        // Add EU data sheet if not empty
        if (euExportedData.length > 0) {
          let worksheet = XLSX.utils.json_to_sheet(euExportedData);
          XLSX.utils.book_append_sheet(workbook, worksheet, 'EU Costs');
        }

        // Add Germany data sheet if not empty
        if (deExportedData.length > 0) {
          let worksheet = XLSX.utils.json_to_sheet(deExportedData);
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Germany Costs');
        }

        // Add Other data sheet if not empty
        if (otherExportedData.length > 0) {
          let worksheet = XLSX.utils.json_to_sheet(otherExportedData);
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Other Costs');
        }

        // Export the workbook to an xlsx file only if there are any sheets
        if (workbook.SheetNames.length > 0) {
          XLSX.writeFile(workbook, 'GoodsCostsByRegion.xlsx');
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    exportFullTableToExcel() {
      this.showExportFullTableStart = true;
      let table = this.$refs.fullTable;
      let data = [];

      // Parse table rows
      for (let i = 0; i < table.rows.length; i++) {
        let row = [];
        for (let j = 0; j < table.rows[i].cells.length; j++) {
          row.push(table.rows[i].cells[j].innerText);
        }
        data.push(row);
      }
      this.showExportFullTableStart = false;

      // Convert the data to a worksheet
      let worksheet = XLSX.utils.aoa_to_sheet(data);

      // Create a new workbook, and add the worksheet to it
      let workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

      // Export the workbook to an xlsx file
      XLSX.writeFile(workbook, 'FinanceReport.xlsx');
    },
    async exportTurnoverPositions(dr) {
      try {
        const dateRange = dr.split(' to ');
        let queryParams = {
          from_date: dateRange[0],
          to_date: dateRange[1]
        };
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/invoice-positions/`, queryParams);
        const exportedData = response.data.results;

        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);

        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

        // Write the workbook to a file
        XLSX.writeFile(workbook, 'InvoicePositions.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async exportInvoicesByTaxType(dr) {
      const dateRange = dr.split(' to ');
      let queryParams = {
        from_date: dateRange[0],
        to_date: dateRange[1]
      };

      await axios
        .get(`${process.env.VUE_APP_ROOT_API}/invoices-by-tax-type/`, {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`,
            'Content-Type': 'application/json',
          },
          params: queryParams,
        })
        .then(response => {
          // Create a new workbook
          let wb = XLSX.utils.book_new();

          // Group the data
          let groupedData = response.data.results.reduce((groups, item) => {
            const group = item.tax_type;
            if (!groups[group]) groups[group] = [];
            groups[group].push(item);
            return groups;
          }, {});

          // For each group, create a new worksheet and add it to the workbook
          for (let group in groupedData) {
            let sheetName = group;
            if (sheetName.length > 31) {
              sheetName = sheetName.substring(0, 31);
            }
            let ws = XLSX.utils.json_to_sheet(groupedData[group]);
            XLSX.utils.book_append_sheet(wb, ws, sheetName);
          }

          // Write the workbook to a file
          XLSX.writeFile(wb, 'ByTaxType.xlsx');
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$router.push({ name: 'login' });
          }
        })
    },
    async exportStaffCosts(dr) {
      try {
        const dateRange = dr.split(' to ');
        let queryParams = {
          from_date: dateRange[0],
          to_date: dateRange[1]
        };
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/staff-cost-corrections/`, queryParams);
        // Create a new workbook
        let wb = XLSX.utils.book_new();

        // Group the data
        let groupedData = response.data.reduce((groups, item) => {
          // Determine the group key based on total_extra_amount
          const groupKey = item.is_freelancer == true ? 'freelancers' : 'regular employees';
          // Initialize the group array if it doesn't exist
          if (!groups[groupKey]) {
            groups[groupKey] = [];
          }
          // Add the item to the appropriate group
          groups[groupKey].push(item);
          return groups;
        }, {});

        // For each group, create a new worksheet and add it to the workbook
        for (let group in groupedData) {
          let sheetName = group;
          if (sheetName.length > 31) {
            sheetName = sheetName.substring(0, 31);
          }
          let ws = XLSX.utils.json_to_sheet(groupedData[group]);
          XLSX.utils.book_append_sheet(wb, ws, sheetName);
        }

        // Write the workbook to a file
        XLSX.writeFile(wb, 'StaffCosts.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async runCompareTurnoverData() {
      if (this.compareTurnoverUploadedFile.length != 0) {
        this.showCompareTurnoverResults = true;
        const formData = new FormData();
        const dateRange = this.compareTurnoverDateRange.split(' to ');
        formData.append('file', this.compareTurnoverUploadedFile);
        formData.append('from_date', dateRange[0]);
        formData.append('to_date', dateRange[1]);

        await axios
          .post(`${process.env.VUE_APP_ROOT_API}/compare-turnover-data/`, formData, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(response => {
            if (response.data.success) {
              this.linkToTurnoverCompareTable = response.data.results.link;
              this.showToast('success', response.data.message);
            } else {
              this.showToast('danger', response.data.message);
            }
            this.showCompareTurnoverResults = false;
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      }
      else {
        this.showToast('warning', 'Please, choose a file');
      }
    },
    async runCompareExpensesData() {
      if (this.compareExpensesUploadedFile.length != 0) {
        this.showCompareExpensesResults = true;
        const formData = new FormData();
        const dateRange = this.compareExpensesDateRange.split(' to ');
        formData.append('file', this.compareExpensesUploadedFile);
        formData.append('from_date', dateRange[0]);
        formData.append('to_date', dateRange[1]);

        if (this.selectedExpensesType == 'Waren Frachtkosten') {
          await axios
            .post(`${process.env.VUE_APP_ROOT_API}/compare-expenses-data/`, formData, {
              headers: {
                Authorization: `JWT ${this.$store.state.jwt}`,
                'Content-Type': 'application/json',
              },
            })
            .then(response => {
              if (response.data.success) {
                this.linkToExpensesCompareTable = response.data.results.link;
                this.showToast('success', response.data.message);
              } else {
                this.showToast('danger', response.data.message);
              }
              this.showCompareExpensesResults = false;
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$router.push({ name: 'login' });
              }
            })
        }
        else {
          await axios
            .post(`${process.env.VUE_APP_ROOT_API}/compare-expenses-freight-charges-data/`, formData, {
              headers: {
                Authorization: `JWT ${this.$store.state.jwt}`,
                'Content-Type': 'application/json',
              },
            })
            .then(response => {
              console.log(response);
              this.showCompareExpensesResults = false;
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$router.push({ name: 'login' });
              }
            })
        }
      }
      else {
        this.showToast('warning', 'Please, choose a file');
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";

.table-wrapper {
  width: 100%;
  overflow: auto;
  max-height: 600px;
}

table {
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
}

thead {
  position: sticky;
  top: 0;
}

.sticky-col {
  position: sticky;
  left: 0;
  background-color: #f3f2f7;
}

.google-drive-files {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}
</style>
